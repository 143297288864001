body {
  padding-top: 1em;
}

.cards {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 1em;
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

